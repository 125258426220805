import React, { useContext } from "react";
import "./styles/module.css";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalState";
import { image } from "../helpers";

//If there is a thumbnail, then show it otherwise show default image
const ModuleStyle = {
  minHeight: "245px",
  height: "100%",
  borderRadius: "17px",
  boxShadow: "0px 0px 4px rgb(0,0,0,0.4)",
};

const fixName = (name) => {
  const charArrays = ["?", "+", "&", "/"];
  charArrays.forEach((c) => {
    name = name.replaceAll(c, "");
  });
  return name;
};

function getFirstHTMLPage(resources) {
  const getLength = (file) => file?.fileUrl && file.fileUrl.split("/").length;
  const otherPages = resources
    .filter((resource) => {
      if (!resource?.fileUrl) return false;
      return resource.fileUrl.endsWith("html");
    })
    .sort((a, b) => getLength(a) - getLength(b));
  return otherPages[0];
}

const getModuleType = (resources) => {
  var result = { notFound: true };
  const modulesTypes = [
    ["html"],
    ["mp4", "mkv", "m4v"],
    ["ppt", "docx", "pdf", "pptx", "pps"],
    ["mp3"],
    // ['png', 'jpg', 'jpeg']
  ];
  var move = 0,
    lastStep = modulesTypes.length - 1;
  while (result.notFound) {
    result = findMainModuleFile(resources, modulesTypes[move], result);
    if (move === lastStep) break;
    move += 1;
  }

  //If many htmls then prioritize html file
  if (result.fileType === "html") {
    result.resource = getFirstHTMLPage(resources);
  }

  return result;
};

//Find the main module to be displayed
const findMainModuleFile = (resources, mainFileTypes, data) => {
  for (let resource of resources) {
    const nameParts = resource.fileName ? resource.fileName.split(".") : "";
    const fileType = nameParts[nameParts.length - 1].toLowerCase();
    if (mainFileTypes.includes(fileType)) {
      data["notFound"] = false;
      data["resource"] = resource;
      data["fileType"] = fileType;
      break;
    }
  }
  return data;
};

const CardStyle = (thumbnail) => ({
  backgroundImage: thumbnail
    ? `url(${thumbnail})`
    : `url(${process.env.PUBLIC_URL + "/backgrounds/modules/pdfImage.png"})`,

  backgroundSize: "cover",
  height: "75%",
  borderRadius: "17px 17px 0px 0px",
});

const Module = ({ modules, fetched, heading, hide }) => {
  const { grade, section } = useContext(GlobalContext).userDetails;
  //Extracting params
  const params = useParams();
  const subject = params.subject;
  const chapter = params.chapter;
  // const topic = params.topic;
  let topicNameString = window.location.href
    .split("/")
    [window.location.href.split("/").length - 1].split("%20");
  const topicName = decodeURIComponent(topicNameString.join(" "));

  // console.log(topic, 'top', topicName);

  //Function to return the file type
  const fileType = (type) => {
    if (type === "png" || type === "jpg" || type === "jpeg" || type === "gif") {
      return "Image";
    } else if (type === "pdf") {
      return "PDFDocument";
    } else if (type === "txt") {
      return "Document";
    } else if (
      type === "mp4" ||
      type === "mkv" ||
      type === "webm" ||
      type === "m4v"
    ) {
      return "Video";
    } else if (type === "swf") {
      return "swfVideo";
    } else if (type === "ppt" || type === "pptx" || type === "pps") {
      return "PowerPoint";
    } else if (type === "html") {
      return "Activity";
    } else if (type === "mp3") {
      return "Audio";
    } else if (type === "docx" || type === "doc") {
      return "WordDocument";
    } else {
      return "Unsupported";
    }
  };

  //Function to decide the action to be executed based on the file type
  const actionType = (name) => {
    const type = fileType(name);
    if (type === "Video") {
      return "watch";
    } else if (type === "Image") {
      return "show";
    } else if (type === "PDFDocument") {
      return "pdfview";
    } else if (
      type === "Document" ||
      type === "PowerPoint" ||
      type === "WordDocument"
    ) {
      return "view";
    } else if (type === "swfVideo") {
      return "watchSWF";
    } else if (type === "Audio") {
      return "listen";
    }
  };

  //Function to compare the thumbnail and video
  const compare = (file) => {
    if (fileType(file) == "Image") {
      return 1;
    } else {
      return 0;
    }
  };

  //Function to fix the file url based on the content type
  const fixFileUrl = (fileUrl, fileName) => {
    if (!fileUrl.startsWith("https://")) {
      fileUrl = fileUrl.split(" ").join("%20");
      fileUrl = "https://xcell-cms-files.s3.amazonaws.com" + fileUrl;
    }

    fileUrl = removeDoubleSlash(fileUrl);

    if (
      fileType(fileName) == "PowerPoint" ||
      fileType(fileName) == "WordDocument"
    ) {
      return "https://view.officeapps.live.com/op/embed.aspx?src=" + fileUrl;
    } else if (fileType(fileName) === "Document") {
      console.log(fileUrl);
      return `${fileUrl}#toolbar=0`;
    } else {
      return fileUrl;
    }
  };

  //function to remove the double slasshes from url path
  const removeDoubleSlash = (url) => {
    const newUrl = new URL(url);
    if (!newUrl.pathname.includes("//")) return url;
    return `${newUrl.origin}${newUrl.pathname.replace("//", "/")}`;
  };

  //Call the page visit api whenever a module is clicked
  const Visited = async (teacherId, module) => {
    const [localGrade, localSection] = localStorage
      .getItem("userGrade")
      .split("-");
    console.log(localGrade, localSection, 1234);
    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/analytics/pagevisits`, {
      method: "POST",
      headers: {
        token: `${window.localStorage.getItem("authToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        teacherId,
        subject,
        chapter,
        topic: params?.topic,
        module,
        grade: grade || localGrade,
        section: section || localSection,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
      });
  };

  return (
    <>
      <div
        className="row module my-3 gy-4"
        style={{ display: hide ? "none" : "flex" }}
      >
        {heading && <h4>{heading}</h4>}
        {modules.length > 0 ? (
          modules.map((module) => {
            var result = getModuleType(module.resources);
            return (
              <div
                className="col-sm-2 col-6"
                style={{ display: result.notFound ? "none" : "block" }}
                onClick={() => {
                  Visited(module.creator, module.name);
                }}
              >
                {/* First check if the module is activity or any other, then make the corresponding component  */}
                {result.fileType !== "html" ? (
                  <NavLink
                    to={{
                      pathname:
                        "/learningresources/modules" +
                        `/${fixName(module.name)}/${actionType(
                          result.fileType
                        )}`,
                      useProps: !result.notFound
                        ? {
                            fileUrl: fixFileUrl(
                              result.resource.fileUrl,
                              result.fileType
                            ),
                            moduleName: result.resource.fileName,
                          }
                        : {
                            fileUrl: "",
                            fileName: "",
                          },
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    {/* <div className='text-center' style={ModuleStyle}> */}
                    <div className="card card-height card-radius">
                      <div className="card-img">
                        <img
                          src={image(module.thumbnail)}
                          alt={module.name || "module"}
                          className="card-thumbnail"
                        />
                      </div>
                      <div
                        className="text-dark text-center"
                        // style={CardStyle(module.thumbnail)}
                        style={{ fontWeight: "bold" }}
                      >
                        <p
                          className="mar-p"
                          dangerouslySetInnerHTML={{ __html: module.name }}
                        ></p>
                        {/* <p>{fileType(result.fileType)}
                      </p> */}
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  <a
                    href={result.resource.fileUrl}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    {/* <div className='text-center' style={ModuleStyle}> */}
                    <div className="card card-height card-radius">
                      <div className="card-img">
                        <img
                          src={image(module.thumbnail)}
                          alt={module.name || "module"}
                          className="card-thumbnail"
                        />
                      </div>
                      <div
                        className="text-dark text-center"
                        // style={CardStyle(module.thumbnail)}
                        style={{ fontWeight: "bold" }}
                      >
                        <p
                          className="mar-p"
                          dangerouslySetInnerHTML={{ __html: module.name }}
                        ></p>
                        {/* <p>{fileType(result.fileType)}
                      </p> */}
                      </div>
                    </div>
                  </a>
                )}
              </div>
            );
          })
        ) : (
          <h5 style={{ textAlign: "center" }}>
            {fetched ? "0 modules to show" : "Loading..."}
          </h5>
        )}
      </div>
    </>
  );
};

export default Module;
